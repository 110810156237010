

export default {
  code: 'ar',
  messages: {
    alpha: '{_field_} يجب ان يحتوي على حروف فقط',
    alpha_num: '{_field_} قد يحتوي فقط على حروف وارقام',
    alpha_dash: '{_field_} قد يحتوي على حروف او الرموز - و _',
    alpha_spaces: '{_field_} قد يحتوي فقط على حروف ومسافات',
    between: 'قيمة {_field_} يجب ان تكون ما بين {min} و {max}',
    confirmed: '{_field_} لا يماثل التأكيد',
    digits: '{_field_} يجب ان تحتوي فقط على ارقام والا يزيد عددها عن {length} رقم',
    dimensions: '{_field_} يجب ان تكون بمقاس {width} بكسل في {height} بكسل',
    email: '{_field_} يجب ان يكون بريدا اليكتروني صحيح',
    excluded: '{_field_} غير صحيح',
    ext: 'نوع ملف {_field_} غير صحيح',
    image: '{_field_} يجب ان تكون صورة',
    integer: '{_field_} يجب ان يكون عدداً صحيحاً',
    length: 'حقل {_field_} يجب الا يزيد عن {length}',
    max_value: '{_field_} يجب ان يكون اصغر من {max} او تساويها',
    max: '{_field_} يجب ان يحتوي على {length} حروف على الأكثر',
    mimes: 'نوع ملف {_field_} غير صحيح',
    min_value: '{_field_} يجب ان تكون اكبر من {min} او تساويها',
    min: '{_field_} يجب ان يحتوي على {length} حروف على الأقل',
    numeric: '{_field_} يمكن ان يحتوي فقط على ارقام',
    oneOf: '{_field_} يجب ان يكون قيمة صحيحة',
    regex: '{_field_} غير صحيح',
    required: '{_field_} مطلوب',
    required_if: '{_field_} مطلوب',
    size: '{_field_} يجب ان يكون اقل من {size} كيلوبايت',
    int: '{_field_} يمكن ان يحتوي فقط على ارقام',
    mobile: '{_field_} غير صحيح',
    fullname: 'الاسم الثلاثي مطلوب',
    false: 'معطل',
    true: 'مفعل',
  },
  names: require('./attributes').default,
  fields: {}
};
