
import {kebabCase} from 'lodash'
import AppRouter from "@views/AppRouter";

export default {
  methods: {
    route(name) {
      name = kebabCase(name)
      return {name};
    },
    isRoute(name) {
      return this.$route.name === name;
    }
  },
  computed: {
    DATES_FORMATS() {
      return {
        'DATE_FORMAT':process.env.DATE_FORMAT,
        'DATE_FORMAT_MOMENTS':process.env.DATE_FORMAT_MOMENTS,
        'HIJRI_DATE_FORMAT':process.env.HIJRI_DATE_FORMAT,
        'HIJRI_DATE_FORMAT_MOMENTS':process.env.HIJRI_DATE_FORMAT_MOMENTS,
      }
    },
    AppLogo() {
      return require(`@images/${this.themeColor}.png`).default;
    },
    AppLogo2() {
      if(this.AppRtl) {
        return require(`@images/alqassim2.png`).default;
      }
      return require(`@images/alqassim.png`).default;
    },
  }
}
