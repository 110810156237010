/*
  SarhSoft Cross-PlatForm Engine Project
  Languages: PHP7.4, JavaScript, Node, Java.
  BackEnd: Laravel8.
  FrontEnd: Vue2.
  Project Type: (Laravel,Vue Mixin) Java Cross Frame.
  Written By: M.Farhat,
  Email: m.farhat@sarhsoft.com.
  Copyright © 2022 SarhSoft All rights reserved.
*/
<script>
import AxiosSelect from '@base-inputs/AxiosSelect';
const prefix = 'Resources';
const Resource = a => `${prefix}/${a}`;
export default {
  name: 'LubricantOrdersSelect',
  extends: AxiosSelect,
  props: {
    clearable: {
      default: () => false
    },
    value: {},
    axiosUrl: {
      default: () => Resource('LubricantOrders')
    }
  }
};
</script>
