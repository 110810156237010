<template>
  <div class="pt-2">
    <app-row class="pa-0">
    <v-col md="6">
      {{parseAttribute('name_ar')}}: <app-datatable-edit-dialog
        v-model="item.name_ar"
        v-on="$listeners"
        :label="parseAttribute('name_ar')"
        :item.sync="item"
        :update-method="updateOneItemData"
        name="name_ar"
        :type-icon="`mdi-account`"/>
    </v-col>
    <v-col md="6" class="ps-2">
    {{parseAttribute('name_en')}}: <app-datatable-edit-dialog
    v-model="item.name_en"
    v-on="$listeners"
    :item.sync="item"
    :update-method="updateOneItemData"
    name="name_en"
    :type-icon="`mdi-account`"
  />
    </v-col>
    </app-row>
  </div>
</template>

<script>
import DatatableMixin from '@mixins/DatatableMixin';

export default {
  name: 'ProfilePartOne',
  helperApiName: 'user',
  mixins: [DatatableMixin],
  props:{
    item: {
      type: Object,
      default: () => {}
    },
  },
  watch:{
    item(v) {
      this.$emit('update:item', v);
    },
  },
};
</script>
