/*
  SarhSoft Cross-PlatForm Engine Project
  Lnaguages: PHP, JavaScript, Node, Java.
  BackEnd: Laravel.
  FrontEnd: Vue2.
  Project Type: (Laravel,Vue Mixin) Java Cross Frame.
  Written By: M.Farhat,
  Email: m.farhat@sarhsoft.com.
  Copyright © 2022 SarhSoft All rights reserved.
*/
import axios from 'axios';
const prefix = 'Resources';
const Resource = a => `${prefix}/${a}`;
export default {
  productYears(params = {}, config = {}) {
    const url = Resource('ProductYears');
    return params === true ? url : axios.get(url, {...config, params: {...params, itemsPerPage: -1}});
  }
};
