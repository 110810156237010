<template>
  <v-btn fab icon tile v-bind="$attrs" v-on="$listeners" x-small>
    <slot></slot>
  </v-btn>
</template>

<script>
export default {
  name: "SystemBarBtn",
}
</script>

<style scoped>

</style>
