export default {
  q: '?',
  sar: 'ريال',
  card_number:'Card Number',
  exp_date:'Expiry Date',
  sec_code:'Security Code',
  false: 'Disabled',
  true: 'Enabled',
  progress: 'Progress ...',
  id: '#',
  ar: 'العربية',
  en: 'English',
  login: 'Login',
  home: 'Home',
  max_price: 'Max price',
  min_price: 'Min price',
  select_def: 'Sort By',
  select_priceLTH: 'Price: Low to High',
  select_priceHTL: 'Price: High to Low',
  select_odoLTH: 'ODO: Low to High',
  select_odoHTL: 'ODO: High to Low',
  select_createdOTN: 'Oldest to Newest',
  select_createdNTO: 'Newest to Oldest',
  added_order_string1: 'Please complete the order amount wired transfer of to our bank account below within next 12 hours',
  car_list: 'Vehicles List',
  lubricant_list: 'Lubricants',
  products: 'Products',
  about: 'About Us',
  AppName3: 'Abdulmajeed Saad Bin Qassim Trading Est',
  contact: 'Contact Us',
  faq: 'F.A.Q',
  lubricantdealer: 'Lubricant Dealers',
  faq_string1: 'Here are the frequently asked questions from our customers.',
  lubricant_string1: 'Note: bank account holder must be same as order beneficiary name',
  our_cars: 'Our Cars',
  my_account: 'My Account',
  my_orders: 'Active Orders',
  my_orders_history: 'Orders History',
  account_information: "Account Information",
  personal_information: "Personal Information",
  identity_information: "Identities Management",
  language: 'View Language',
  done: 'Done',
  logout: 'Logout',
  pickup: 'Pick your Order from our office',
  cancel: 'Cancel',
  yes: 'Yes',
  no: 'No',
  enabled: 'Enabled',
  disabled: 'Disabled',
  save: 'Save',
  save_password: 'Update Password',
  change: 'Change',
  options: 'options',
  create: 'Create',
  store: 'Store',
  update: 'Update',
  destroy: 'Delete',
  upload: 'Upload',
  click_to_upload: 'Click here to upload',
  delete: 'Delete',
  show: 'Show',
  view: 'View',
  control: 'Control',
  title: 'Title',
  none: 'None',
  na: 'Na',
  export: 'Export',
  close: 'Close',
  edit: 'Edit',
  add: 'Add',
  register: 'Register',
  print: 'Print',
  today: 'Today',
  day: 'Day',
  date: 'Date',
  now: 'Now',
  am: 'AM',
  pm: 'PM',
  error: 'Error !!',
  search: 'Search',
  exportPdf: 'export to PDF',
  exportExcel: 'export to Excel',
  ImportExcel: 'Import from Excel',
  printContractPDF: 'print Contract/PDF',
  printBillPDF: 'print Bill/PDF',
  refreshTable: 'Refresh Page',
  price: 'Price',
  view_filters: "Filters",
  total: "Total",
  total2: "Total completed with customs delivered",
  the_total: "Total",
  ExtraInfo: "Detailed Info",
  down_with_vat: "Down Payment VAT/incl",
  brokerage_with_vat: "Brokerage VAT/incl",
  brokerage_services: 'Brokerage Service',
  sr: " SR",
  contain: 'Contain Qty',
  contain1: 'Contain Qty',
  bill_number: "Bill NO",
  contract_number: "Contact No",
  expansionPanelHeaderOptions: 'Control',
  close_window: 'Close Window',
  year: 'Year',
  showDetails: 'Show Details',
  Details: 'Details ',
  information: 'Information ',
  car: 'Vehicle',
  car_manufacture: 'Manufacture',
  car_name: 'Vehicle Name',
  product_name: 'Product Name',
  car_year: 'Manufacture Year',
  car_km: 'Vehicle ODO Counter',
  car_chassie: 'Chassie',
  car_out_color: 'Outter Color',
  car_in_color: 'Inner Color',
  car_fuel_type: 'Fuel Type',
  order: 'Order',
  show_order: 'Order Details',
  show_notificaions: 'Notifications',
  show_notificaion: 'Notification Details',
  order_now: 'Order Now',
  dealer_now: 'Find a Dealer',
  select_lubricant: 'Select Products',
  customer_info: 'Receiver Info',
  review_order: 'Review Order',
  confirm_order: 'Confirm Order',
  car_order: 'Order Vehicle',
  lubricant: 'Lubricants',
  car_order1: 'You will be able to order this vehicle at: ',
  car_to: 'To',
  car_photo_show: 'Show Zoomed Content',
  cant_order: 'to Order this Vehicle you need to verify you Mobile Number and at least one verified Identity',
  down_payment: 'Down Payment',
  last_payment: 'Last payment',
  setting: 'Setting',
  month: 'Month',
  non_defined: 'Un-Defined',
  undefined: 'Un-Defined',
  greg_letter: ' AD',
  hijri_letter: ' AH',
  import: 'Import',
  twitter:   'Twitter',
  facebook:  'Facebook',
  instagram: 'Instagram',
  snapchat:  'Snapchat',
  telegram:  'Telegram',
  youtube:  'Youtube',
  copyright: 'All Rights Reserved to Alqassim Co.LTD',
  first: 'First',
  last: 'Last',
  previous: 'Previous',
  next: 'Next',
  rest: "Rest",
  latest: 'Whats New',
  get_latest_updates: 'Get the latest updates.',
  my_profile: 'My Profile',
  follow_on_snapchat: 'Follow us on Snapchat',
  follow_on_telegram: 'Follow us on Telegram',
  special_vehicles: 'Special Vehicles',
  browse_by_brands: 'Browse Vehicles by Brand',
  multiple_vehicles_types: 'Multiple Vehicles Types',
  hyundai: 'Hyundai',
  kia: 'Kia',
  unit: 'Unit',
  description: 'Car Description',
  item_description: 'Product Name',
  order_total: 'Order Total',
  order_vat: 'VAT',
  order_delivery: 'Delivery/Shippment Fees',
  unit1: 'Box',
  unit2: 'Piece',
  detect_location:'Detect Location',
  selected_position:'Selected Position',
  how_we_make_it_done: 'How We Make It Done',
  you_might_also_like: 'You might also like',
  verify_mobile: 'Mobile Verification!!!',
  verify_email: 'E-Mail Verification!!!',
  verify_approve_title: 'Agree on Contract terms verification',
  verify_mobile_title: 'Mobile Number Verification',
  verify_email_title: 'E-Mail Verification',
  not_verified_mobile: 'Mobile Number unverified!!!',
  not_verified_email: 'E-Mail unverified!!!',
  verify_mobile_text1: 'Activation code sent to Your mobile: ',
  verify_email_text1: 'Activation code sent to Your E-Mail: ',
  verify_mobile_text2: 'Please enter received activation code: ',
  verify_email_text2: 'Please enter received activation code: ',
  verify_mobile_text3: 'You can request a new activation code If you not receive the activation code during: ',
  verify_email_text3: 'You can request a new activation code If you not receive the activation code during: ',
  verify_email_text4: ' Provide you instant notification of our latest offers and vehicles',
  upload_identity_photo: 'Upload Identity Photo',
  upload_BankRecipt_photo: 'Upload Bank Recipt Photo',
  bank_details: 'Refund Bank Details',
  BankRecipt: 'Bank Recipt',
  selectAll: 'select All',
  contact_name1:'Mohammed Rabiea',
  contact_name2:'Abdulmajeed bin Qassim',
  contact_name3:'Ibrahim',
  contact_owner:'Owner',
  contact_korea:'Republic of South Korea',
  contact_sa:'Kingdom Of Saudi Arabia',
  contact_address1:'Southern Ring Branch Rd، Alhazm، Riyadh 14963',
  contact_address2:'Ingwon-ro, Yeonsu-gu, Incheon, South Korea',
  product_subscription:'Subscribe to get notified when vehicle available',
  product_no_subscription:'Subscribed to notifications click to cancel',
  step_1:'Our Picks',
  step_2:'Our Purchases',
  step_3:'Our Offers',
  step_4:'Checkout',
  step_5:'Shipping & Receiving',
  step_6:'Sales To The World',
  step_1_string:'Our team participates daily, from Monday to Friday, in South Korean car auctions, to meet the needs of the local markets, and to choose what is appropriate and required to buy.',
  step_2_string:'After purchasing the vehicles (if available) from the car auctions, our team will check what is required for maintenance and count the costs of each vehicle.',
  step_3_string:'After the checking the vehicles, our work team will photograph the vehicles with all their details, including defects and features, and clarify them, and then offer the vehicle for sale on our website.',
  step_4_string:'After choosing the appropriate vehicle from the offers and requesting a reservation, part of the vehicle amount will be deducted as a token, the customer will enter his complete data, the customer will receive the purchase invoice and the order number, and the customer will be contacted to complete the rest of the procedures.',
  step_5_string:'After completing the procedures of the vehicle in Korea under the name of the buyer, it will be booked in the nearest shipping to the Kingdom of Saudi Arabia, and a WhatsApp group will be created within a week after the ship has sailed. The Buyer can receive the car in Dammam or it can be shipped by Al-Bassami Transport Group to the city chosen.',
  step_6_string:'But in the event that the buyer wishes to buy to a country other than the Kingdom of Saudi Arabia, the request will be registered in the “My Requests” box, and the order will be provided personally, and each country has a price and regulations in terms of shipping and other things.',
};
