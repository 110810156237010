export default {
  product_id: 'المنتج',
  verfied_user_identity: 'هويات المستخدم المفعلة',
  country_id: 'الدولة',
  city_id: 'المدينة',
  order_id: 'الطلب',
  city_bassami: 'فرع البسامي',
  nationality_id: 'الجنسية',
  identity_status: 'حالة الهوية',
  identity_type: 'نوع الهوية',
  identity_type1: 'هوية وطنية',
  identity_type2: 'هوية مقيم',
  user_type: 'نوع الحساب',
  user_type0: 'غير محدد',
  user_type1: 'فرد ( مستخدم )',
  user_type2: 'تاجر فردي',
  user_type3: 'تاجر ( بسجل تجاري )',
  user_status: 'حالة الحساب',
  user_status1: 'جديد/قيد التفعيل',
  user_status2: 'مفعل',
  user_status3: 'موقوف',
  order_status: 'حالة الطلب',
  totals: 'إجمالي الطلب',
  recipient_name: 'اسم المستلم بالبسامي',
  recipient_mobile: 'رقم جوال المستلم بالبسامي',
  temp_plate: 'لوحات ( مؤقته) جمركية',
  fasah_auth: 'تفويض منصة فسح',
  refund_name: 'اسنم المستفيد',
  refund_iban: 'رقم الأيبان/IBan',
  refund_iban2: 'رقم الأيبان/IBan بدون SA',
  refund_bank: 'اسم البنك',
};
