<template>
  <v-navigation-drawer
      v-bind="$attrs"
      v-on="$listeners"
      :expand-on-hover="expandOnHover"
      :mini-variant.sync="miniComputed"
      :right="AppRtl"
      :value="value"
      v-if="this.AppIsMobile"
      app
      clipped>
    <app-drawer-list :items="items"/>
    <template v-slot:prepend>

        <v-list-item class="px-2" two-line>

        </v-list-item>
      <v-divider></v-divider>
    </template>
    <template v-slot:append>

    </template>
  </v-navigation-drawer>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
export default {
  name: "Navigation",
  props: {
    value: {
      type: Boolean,
      default() {
        return !this.mini
      }
    },
    expandOnHover: {
      type: Boolean,
      default() {
        return !0
      }
    },
    mini: {
      type: Boolean,
      default() {
        return this.AppIsMobile
      }
    }
  },
  data() {
    return {
      logoutLoading: !1,
      items: [
        {
          id: 1,
          title:this.$t('home'),
          menu_url: '/home',
          name: 'home',
          menu_parent_id: 0,
          sort_order: 1,
          menu_type: 1,
          perm: 'home',
          icon: 'mdi-home',
          system_type: '*',
          model_name: 'Home',
          status: 1,
        },
        {
          id: 2,
          title:this.$t('car_list'),
          menu_url: '/products',
          name: 'products',
          menu_parent_id: 0,
          sort_order: 2,
          menu_type: 1,
          perm: 'products',
          icon: 'mdi-archive',
          system_type: '*',
          model_name: 'Products',
          status: 1,
        },
        // {
        //   id: 22,
        //   title:this.$t('lubricant_list'),
        //   menu_url: '/lubricant-categories',
        //   name: 'lubricant-categories',
        //   menu_parent_id: 0,
        //   sort_order: 3,
        //   menu_type: 1,
        //   perm: 'lubricants',
        //   icon: 'mdi-archive',
        //   system_type: '*',
        //   model_name: 'Lubricants',
        //   status: 1,
        // },
        {
          id: 3,
          title:this.$t('contact'),
          menu_url: '/contact',
          name: 'contact',
          menu_parent_id: 0,
          sort_order: 3,
          menu_type: 1,
          perm: 'contact',
          icon: 'mdi-email',
          system_type: '*',
          model_name: 'User',
          status: 1,
        },
        {
          id: 4,
          title:this.$t('about'),
          menu_url: '/about-us',
          name: 'about',
          menu_parent_id: 0,
          sort_order: 4,
          menu_type: 1,
          perm: 'contact',
          icon: 'mdi-email',
          system_type: '*',
          model_name: 'User',
          status: 1,
        },
        {
          id: 5,
          title:this.$t('faq'),
          menu_url: '/faq',
          name: 'faq',
          menu_parent_id: 0,
          sort_order: 5,
          menu_type: 1,
          perm: 'faq',
          icon: 'mdi-frequently-asked-questions',
          system_type: '*',
          model_name: 'User',
          status: 1,
        },
        {
          id: 6,
          title:this.$t('my_account'),
          menu_url: '/my-account',
          name: 'my-account',
          menu_parent_id: 0,
          sort_order: 6,
          menu_type: 2,
          perm: 'my_account',
          icon: 'mdi-account',
          system_type: '*',
          model_name: 'User',
          status: 1,
          items:[
            {
              id: 9,
              title:this.$t('show_notificaions'),
              menu_url: '/my-account',
              name: 'my-account',
              menu_parent_id: 6,
              sort_order: 1,
              menu_type: 1,
              perm: 'my_account',
              icon: 'mdi-account',
              system_type: '*',
              model_name: 'User',
              status: 1,
            },
            {
              id: 7,
              title:this.$t('account_information'),
              menu_url: '/my-account/my-profile',
              name: 'profile',
              menu_parent_id: 6,
              sort_order: 1,
              menu_type: 1,
              perm: 'my_account',
              icon: 'mdi-account',
              system_type: '*',
              model_name: 'User',
              status: 1,
            },
            {
              id: 10,
              title:this.$t('identity_information'),
              menu_url: '/my-account/user-identity',
              name: 'user-identity',
              menu_parent_id: 6,
              sort_order: 1,
              menu_type: 1,
              perm: 'user_identity',
              icon: 'mdi-account',
              system_type: '*',
              model_name: 'UserIdentity',
              status: 1,
            },
            {
              id: 8,
              title:this.$t('my_orders'),
              menu_url: '/my-account/my-orders',
              name: 'my-orders',
              menu_parent_id: 6,
              sort_order: 2,
              menu_type: 1,
              perm: 'my_orders',
              icon: 'mdi-account',
              system_type: '*',
              model_name: 'Order',
              status: 1,
            },
            {
              id: 12,
              title:this.$t('my_orders_history'),
              menu_url: '/my-account/my-orders-history',
              name: 'my-orders-history',
              menu_parent_id: 6,
              sort_order: 3,
              menu_type: 1,
              perm: 'my_orders_history',
              icon: 'mdi-account',
              system_type: '*',
              model_name: 'Order',
              status: 1,
            },
          ],
        },
      ],
    }
  },
  computed: {
    // ...mapGetters('sideMenu', {items: 'getItems'}),
    miniComputed: {
      get() {
        // if (this.AppIsMobile && this.mini) {
        //   const mini = !1;
        //   this.setMini(mini)
        //   return mini;
        // }
        return this.mini
      },
      set(v) {
        this.setMini(v)
      },
    },
    isFetchItems: {
      set(v) {
        mapMutations('sideMenu', ['setFetching']).setFetching.call(this, v)
      },
      get() {
        return mapGetters('sideMenu', ['getFetching']).getFetching.call(this)
      },
    },
    user() {
      return this.$root.authUser
    },
    loadingUser() {
      return !Boolean(this.user)
    }
  },
  methods: {
    clickClose() {
      this.AppIsMobile && this.setDrawer(!this.value) || this.setMini(!this.miniComputed)
    },
    setDrawer(v) {
      this.$emit('input', Boolean(v))
    },
    setMini(v = !1) {
      this.$emit('mini', Boolean(v))
    },
    logout() {
      this.setDrawer(!1)
      this.logoutLoading = !0
      this.$emit('drawer', false)
      this.confirm(this.$t('messages.sure_logout'), () => {
        this.$api.methods.auth.logout().finally(() => this.logoutUser(true))
      }, () => {
        this.logoutLoading = !1
      })
    }
  }
}
</script>

<style scoped>
.drawer-footer {
  margin: 0;
  white-space: nowrap;
}
</style>
