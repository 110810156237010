
export default {
  name: 'الاسم',
  name_hint: 'حقل الاسم مطلوب',
  name_ar: 'الاسم العربي',
  name_ar_hint: 'حقل الاسم العربي مطلوب',
  name_en: 'الاسم الإنجليزي',
  name_en_hint: 'حقل الاسم الإنجليزي مطلوب',
  login_id: 'اسم المستخدم',
  login_id_hint: 'حقل اسم المستخدم مطلوب',
  password: 'كلمة المرور',
  password_hint: 'حقل كلمة المرور مطلوب',
  identity: 'رقم الهوية',
  identity_hint: 'حقل رقم الهوية مطلوب',
  password_confirmation: 'تأكيد كلمة المرور',
  password_confirmation_hint: 'حقل تأكيد كلمة المرور مطلوب',
  age: 'العمر',
  age_hint: 'حقل العمر مطلوب',
  birth_date: 'تاريخ الميلاد',
  birth_date_hint: 'حقل تاريخ الميلاد مطلوب',
  mobile: 'رقم الجوال',
  mobile_hint: 'رقم الجوال مطلوب',
  sort_order: 'ترتيب العرض',
  active: 'فعال',
  percent: 'النسبة',
  percentage: 'النسبة',
  status: 'الحالة',
  status_label: 'الحالة',
  other: 'غير ذلك',
  false: 'معطل',
  true: 'مفعل',
  notes: 'ملاحظات',
  notes_hint: 'حقل ملاحظات مطلوب',
  type:'النوع',
  type_hint:'حقل النوع مطلوب',
  phone: 'رقم الهاتف',
  phone_hint: 'حقل رقم الهاتف مطلوب',
  email: 'البريد الإلكتروني',
  email_hint: 'حقل البريد الإلكتروني مطلوب',
  fax: 'رقم الفاكس',
  fax_hint: 'حقل رقم الفاكس مطلوب',
  download: 'تحميل',
  download_view: 'تحميل / عرض',
  request: 'طلب',
  new: 'جديد',
  verify: 'تحقق',
  verification_code: 'كود تفعيل',
  approve: 'الموافقة',
  approve_contract_terms: ' على شروط العقد',
  refresh: 'إعادة',
  refuse: 'رفض',
  completed: 'اكتمال',
  refund: 'المعلومات البنكية لطلب مرتجع',
  payment: 'دفع',
  cancel: 'إلغاء',
  move: 'نقل',
  convert: 'تحويل',
  payment_method_ap: 'Apple Pay',
  shippment_info: 'معلومات الشحن',
  Youtube_info: 'كيفية عمل تفويض منصة فسح',
  Youtube_info_click: 'انقر هنا لمعرفة كيفية عمل تفويض منصة فسح',
  Info_info_click: 'انقر هنا لمعلومات تفصيلية',
  temp_plate_text1: 'هل تريد اضافة لوحات ( مؤقته ) جمركية برسوم 300 ريال؟',
  temp_plate_text2: 'في حال تفعيل هذا الاختيار يجب تحويل 300 ريال لحساب المؤسسة خلال 48 ساعة وارسال ايصال التحويل للأخ ابراهيم بخدمة العملاء',
  forgot_user:  'نسيت اسم المستخدم؟',
  forgot_user_send:  'ارسال اسم المستخدم',
  forgot_password:  'نسيت كلمة المرور؟',
  forgot_password_send:  'ارسال كلمة المرور',
  youAreLoggedIn:  'تم تسجيل دخولك',
  change_password_success:  'تم تغير كلمة المرور بنجاح',
  change_password:  'تغيير كلمة المرور',
  confirm_password:  'تأكيد كلمة المرور',
  current_password:  'كلمة المرور الحالية',
  confirm_current_password_first: 'الرجاء تأكيد كلمة مرورك أولاً',
  coupon_id: 'كوبون خصم',
}
