

<template>
  <v-toolbar :class="{'d-inline-flex': flex }" :color="color" dark v-bind="$attrs" v-on="$listeners">
    <v-toolbar-title>
      <slot></slot>
    </v-toolbar-title>
  </v-toolbar>
</template>

<script>
export default {
  name: "Title",
  props: {
    flex: {
      type: Boolean,
      default: () => !1
    },
    color: {
      type: String,
      default: () => 'primary',
    },
  }
}
</script>
