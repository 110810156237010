<template>
    <v-footer class="font-weight-bold" style="color:#ffffffb3;background-color: #BF0100 !important;" height="30" padless elevation="9">
      <app-row class="font-weight-bold" style="color:#ffffffb3;background-color: #BF0100 !important;" height="30" justify="center" elevation="9" no-gutters>
        <v-col class="text-center font-weight-bold" style="color:#ffffffb3;background-color: #BF0100 !important;" :cols="AppIsMobile?12:4">
          {{$t('copyright')}} © {{ new Date().getFullYear() }}
        </v-col>
        <v-col :class="getClasses" style="color:#ffffffb3;background-color: #BF0100 !important;"
            :cols="AppIsMobile?12:8">
          <v-btn class="app-dt-btn my-1" max-width="22" max-height="22" fab x-small target="_blank" href="https://twitter.com/alqassim_cars"
              style="background: #ffffffb3;" icon :title="$t('twitter')">
            <v-icon style="margin-right: 0;color: #e21713;">mdi-twitter</v-icon>
          </v-btn>&nbsp;&nbsp;
          <v-btn class="app-dt-btn my-1" max-width="22" max-height="22" fab x-small target="_blank" href="https://facebook.com/alqassim.cars"
              style="background: #ffffffb3;" icon :title="$t('facebook')">
            <v-icon style="margin-right: 0;color: #e21713;">mdi-facebook</v-icon>
          </v-btn>&nbsp;&nbsp;
          <v-btn class="app-dt-btn my-1" max-width="22" max-height="22" fab x-small target="_blank" href="https://snapchat.com/add/alqassim.cars"
              style="background: #ffffffb3;" icon :title="$t('snapchat')">
            <v-icon style="margin-right: 0;color: #e21713;">mdi-snapchat</v-icon>
          </v-btn>&nbsp;&nbsp;
          <v-btn class="app-dt-btn my-1" max-width="22" max-height="22" fab x-small target="_blank" href="https://instagram.com/alqassim.cars/"
              style="background: #ffffffb3;" icon :title="$t('instagram')">
            <v-icon style="margin-right: 0;color: #e21713;">mdi-instagram</v-icon>
          </v-btn>&nbsp;&nbsp;
          <v-btn class="app-dt-btn my-1" max-width="22" max-height="22" fab x-small target="_blank" href="https://youtube.com/channel/UC8ba_qHDE8-k6qXfIbk6Jew"
              style="background: #ffffffb3;" icon :title="$t('youtube')">
            <v-icon style="margin-right: 0;color: #e21713;">mdi-youtube</v-icon>
          </v-btn>&nbsp;&nbsp;&nbsp;
          <v-btn class="app-dt-btn my-1 me-2" max-width="22" max-height="22" fab x-small target="_blank" href="https://t.me/alqassim_cars" style="background: #ffffffb3;" icon :title="$t('telegram')">
            <v-icon style="margin-right: 0;color: #e21713;">mdi-telegram</v-icon>
          </v-btn>
        </v-col>
      </app-row>
    </v-footer>
</template>

<script>
export default {
  name: "MainFooter",
  computed: {
    getClasses() {
      if(this.AppIsMobile) {
        return 'text-center font-weight-bold';
      }
      return 'text-end pe-2 font-weight-bold';
    }
  },
}
</script>

<style scoped>

</style>
