import axios from 'axios';

const prefix = a => 'Panel/Notification' + (a ? `/${a}` : '');

export default {
  index(params, config = {}) {
    const url = prefix();
    return params === true ? url : axios.get(url, {...config, params});
  },
  show(id, config = {}) {
    const url = prefix(id);
    return id === true ? url : axios.get(url, config);
  },
};
