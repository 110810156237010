

<template>
  <v-btn
      text tile
      v-bind="$attrs"
      v-on="$listeners"
  >
    <slot></slot>
  </v-btn>
</template>

<script>

export default {
  name: "SnackbarBtn",
}
</script>
