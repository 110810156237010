export default {
    identity_status: 'حالة الهوية',
    identity_status0: 'بانتظار رفع صورة الهوية',
    identity_status1: 'قيد المراجعة',
    identity_status2: 'صورة الهوية غير مطابقة للبيانات المدخلة',
    identity_status3: 'مرفوضة',
    identity_status4: 'مفعلة',
    identity_status5: 'صورة الهوية المرفوعة فارغة',
    identity_type: 'نوع الهوية',
    identity_type1: 'هوية وطنية',
    identity_type2: 'هوية مقيم',
    identity_photo: 'صورة الهوية',
    BankRecipt: 'إيصال التحويل البنكي',
};
