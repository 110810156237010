export default {
  product_sku: 'SKU',
  manufacturer_id: 'الشركة المصنعة',
  product_model_id: 'نوع المركبة',
  product_year_id: 'سنة التصنيع',
  product_out_color_id: 'اللون الخارجي',
  product_in_color_id: 'اللون الداخلي',
  import_type_id: 'نوع الإستيراد',
  fuel_type_id: 'نوع الوقود',
  km: 'كيلو متر',
  chassie: 'رقم الهيكل',
  product_type: 'نوع المنتج',
  price: 'السعر',
  down_payment: 'الدفعة المقدمة',
  last_payment: 'الدفعة الأخيرة',
  body_check: 'فحص البودي',
  product_notes: 'مواصفات اضافية',
  product_type0: 'لا يوجد',
  product_type1: 'سيارة',
  product_type2: 'قطعة غيار',
  stamp_status: 'حالة الختم',
  stamp_status0: 'تعطيل الختم',
  stamp_status1: 'تفعيل الختم',
  location: 'الموقع',
  stamp_location: 'موقع الختم',
  stamp_location0: 'موقع الختم',
  stamp_location1: 'موقع الختم',
  stamp_location2: 'موقع الختم',
  stamp_location3: 'موقع الختم',
  stamp_location4: 'موقع الختم',
  stamp_location5: 'موقع الختم',
  stamp_color: 'لون الختم',
  stamp_string_en: 'نص الختم بالانجليزي',
  stamp_string_ar: 'نص الختم بالعربي',
};
