
export default {
  name: 'Name',
  name_hint: 'Name field required',
  name_ar: 'Arabic Name',
  name_ar_hint: 'Arabic Name field required',
  name_en: 'English Name',
  name_en_hint: 'English Name field required',
  login_id: 'UserName',
  login_id_hint: 'UserName field required',
  password: 'Password',
  password_hint: 'Password field required',
  password_confirmation: 'Password Confirmation',
  password_confirmation_hint: 'Password Confirmation field required',
  age: 'Age',
  age_hint: 'Age field required',
  birth_date: 'Birth Date',
  birth_date_hint: 'Birth Date field required',
  mobile: 'Mobile',
  mobile_hint: 'Mobile field required',
  sort_order: 'Sort order',
  active: 'Enabled',
  percent: 'Percentage',
  percentage: 'Percentage',
  status: 'Status',
  status_label: 'Status',
  other: 'Other',
  false: 'Disabled',
  true: 'Enabled',
  notes: 'Notes',
  notes_hint: 'Notes field required',
  type:'Type',
  type_hint:'Type field required',
  phone: 'Phone',
  phone_hint: 'Phone field required',
  email: 'Email',
  email_hint: 'Email field required',
  fax: 'Fax',
  fax_hint: 'Fax field required',
  download: 'Download',
  download_view: 'Download / View',
  request: 'Request',
  new: 'New',
  verify: 'Verify',
  verification_code: 'Verification Code',
  approve: 'approve',
  approve_contract_terms: ' Contract Terms',
  refresh: 'Retry',
  refuse: 'Refuse',
  completed: 'Completed',
  refund: 'Bank Details to refund order',
  payment: 'Pay',
  cancel: 'Cancel',
  move: 'Move',
  convert: 'Convert',
  payment_method_ap: 'Apple Pay',
  shippment_info: 'Shippment Information',
  Youtube_info: 'How to create Fasah platform Code',
  Youtube_info_click: 'Click for how to create Fasah platform Code',
  Info_info_click: 'Click for more details',
  temp_plate_text1: 'Do you want to add customs (temporary) plates with a fee of 300 riyals?',
  temp_plate_text2: 'If this selection is activated, you must transfer 300 riyals to organizations bank account within 48 hours and send the transfer receipt to Mr. Ibrahim in customer service.',
  forgot_user:                    'Forgot Your UserName?',
  forgot_password:                'Forgot Your Password?',
  youAreLoggedIn:                 'You are logged in',
  change_password_success:        'Password changed successfully',
  change_password:                'Change Password',
  confirm_password:               'Confirm Password',
  current_password:               'Current password',
  confirm_current_password_first: 'Please confirm your Current password first.',
  coupon_id: 'Discount Coupon',
}
