<template>
  <v-app-bar
    :height="!this.AppIsMobile?100:50"
      v-bind="$attrs"
      v-on="$listeners"
      :clipped-left="!AppRtl"
      :clipped-right="AppRtl"
      class="text-light"
      style="color: #ffffffb3;background: -webkit-linear-gradient(top, rgb(255 255 255) 0%, rgb(165 169 197) 100%) !important"
      app
      color="primary">
    <v-app-bar-nav-icon
      id="drawerBTN"
      v-if="showDrawerIcon&&this.AppIsMobile"
      @click.stop="drawer = !drawer"></v-app-bar-nav-icon>&nbsp;
    <v-avatar style="cursor: pointer;" @click="clickme" rounded :width="!AppIsMobile?300:150" :height="!AppIsMobile?90:45">
      <v-img contain :src="AppLogo2"></v-img>
    </v-avatar>
    <v-row class="mx-1 justify-center" v-if="!AppIsMobile" style="width: 100%">
      <v-toolbar-title class="ma-0 pa-0 mx-2">
        <v-btn :to="route('home')"
            :exact-active-class="activeClasses" :active-class="activeClasses"
            style="font-size: 1rem;border-radius: 15px 0;color: #ffffffb3; background: rgb(42 54 134);"
            class="font-weight-bold px-6 py-4">
          {{ $t('home') }}
        </v-btn>
      </v-toolbar-title>
      <v-toolbar-title class="ma-0 pa-0 mx-2">
          <v-btn :to="route('products')"
              :exact-active-class="activeClasses" :active-class="activeClasses"
                 style="font-size: 1rem;border-radius: 15px 0;color: #ffffffb3; background: rgb(42 54 134);"
                 class="font-weight-bold px-6 py-4">
            {{ $t('car_list') }}
          </v-btn>
      </v-toolbar-title>
      <v-toolbar-title class="ma-0 pa-0 mx-2">
          <v-btn :to="route('contact')"
              :exact-active-class="activeClasses" :active-class="activeClasses"
                 style="font-size: 1rem;border-radius: 15px 0;color: #ffffffb3; background: rgb(42 54 134);"
                 class="font-weight-bold px-6 py-4">
            {{ $t('contact') }}
          </v-btn>
      </v-toolbar-title>
      <v-toolbar-title class="ma-0 pa-0 mx-2">
          <v-btn :to="route('about-us')"
              :exact-active-class="activeClasses" :active-class="activeClasses"
                 style="font-size: 1rem;border-radius: 15px 0;color: #ffffffb3; background: rgb(42 54 134);"
                 class="font-weight-bold px-6 py-4">
            {{ $t('about') }}
          </v-btn>
      </v-toolbar-title>
      <v-toolbar-title class="ma-0 pa-0 mx-2">
          <v-btn :to="route('faq')"
              :exact-active-class="activeClasses" :active-class="activeClasses"
                style="font-size: 1rem;border-radius: 15px 0;color: #ffffffb3; background: rgb(42 54 134);"
                 class="font-weight-bold px-6 py-4">
            {{ $t('faq') }}
          </v-btn>
      </v-toolbar-title>
      <!--<v-toolbar-title v-if="!isLogin" class="ma-0 pa-0 mx-2">
          <v-btn @click="LoginModalActivatior"
              :exact-active-class="activeClasses" :active-class="activeClasses"
                 style="font-size: 1rem;border-radius: 15px 0;color: #ffffffb3; background: rgb(42 54 134);"
                 class="font-weight-bold px-6 py-4">
            {{ $t('my_account') }}
          </v-btn>
      </v-toolbar-title>-->
      <v-toolbar-title v-if="isLogin" class="ma-0 pa-0 mx-2 me-0">
          <v-btn :to="route('/my-account')"
              :exact-active-class="activeClasses" :active-class="activeClasses"
                 style="font-size: 1rem;border-radius: 15px 0;color: #ffffffb3; background: rgb(42 54 134);"
                 class="font-weight-bold px-6 py-4">
            {{ $t('my_account') }}
          </v-btn>
      </v-toolbar-title>
      <v-badge v-if="GetUserNotifs>0&&isLogin" :left="AppRtl" color="error" :content="GetUserNotifs" overlap></v-badge>
    </v-row>
    <v-spacer></v-spacer>
    <template>
      <!--<v-btn v-if="!isLogin" @click="LoginModalActivatior"
             :exact-active-class="activeClasses" :active-class="activeClasses"
             :title="$t('login')" class="font-weight-bold px-2 py-4"
             style="width:auto !important;font-size: 1rem;border-radius: 15px;color: #ffffffb3; background: rgb(42 54 134);">
        {{$t('login')}}
      </v-btn>
      <v-btn v-if="isLogin&&!this.AppIsMobile" @click="logout"
             :exact-active-class="activeClasses" :active-class="activeClasses"
             class="font-weight-bold px-2 py-4" :title="$t('logout')"
             style="width:auto !important;font-size: 1rem;border-radius: 15px;color: #ffffffb3; background: rgb(42 54 134);">
        {{$t('logout')}}
      </v-btn>-->
      <v-btn @click="LoginModalActivatior" v-if="!isLogin" :size="getsize" class="justify-center" :left="AppRtl" style="color: rgb(42, 54, 134);" icon  :title="$t('login')">
        <v-icon :size="getsize2">mdi-lock-open</v-icon>
      </v-btn>
      <v-btn @click="logout" v-if="isLogin&&!this.AppIsMobile" :size="getsize" class="justify-center" :left="AppRtl" style="color: rgb(42, 54, 134);" icon  :title="$t('logout')">
        <v-icon :size="getsize2">mdi-logout</v-icon>
      </v-btn>
      <v-btn v-show="user" :to="'/my-account/my-profile'" v-if="isLogin&&!this.AppIsMobile" :size="getsize" class="justify-center" :left="AppRtl" style="color: rgb(42, 54, 134);" icon  :title="$t('my_profile')">
        <v-icon :size="getsize2">mdi-account</v-icon>
      </v-btn>
      <v-menu :left="!AppRtl" :right="AppRtl" class="justify" offset-y tile>
        <template v-slot:activator="{ on, attrs }">
          <v-btn :size="getsize" class="justify-center" :left="AppRtl" style="color: rgb(42, 54, 134);" icon v-bind="attrs" v-on="on" :title="$t('language')">
            <v-icon :size="getsize2">mdi-web</v-icon>
          </v-btn>
        </template>
        <v-list dense tile>
          <v-list-item-group v-model="AppLocale">
            <template v-for="(item, i) in AppLocales">
              <v-list-item
                :disabled="item.code === AppLocale"
                :value="item.code"
                @click="SetAppLocale(item.code)">
                <v-list-item-title class="text-center">{{ item.title }}</v-list-item-title>
              </v-list-item>
            </template>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </template>
    <v-dialog scrollable :fullscreen="AppIsMobile" v-model="RegisterDialog" v-if="RegisterDialog">
      <app-row class="ma-0" width="100%" style="background: #f9f9f9;" align="center" justify="center">
        <v-col class="pa-0" style="background: #f9f9f9;" cols="12">
          <app-form :errors="errors" :submit="register" ref="registerform">
            <v-card elevation="12" tile class="pa-0">
              <v-toolbar class="justify-content-center" max-height="60" color="primary darken-2" dark flat>
                <v-toolbar-title>{{ $t('register') }}</v-toolbar-title>
              </v-toolbar>
              <v-card-text class="pa-0 ma-0">
                <div class="pt-1">
                  <app-text-input md="6" sm="12"
                    v-model="formData.name_ar"
                    :label="$t('attributes.name_ar')"
                    name="name_ar"
                    rules="required|fullname"/>
                  <app-text-input md="6" sm="12"
                    v-model="formData.name_en"
                    :label="$t('attributes.name_en')"
                    name="name_en"
                    rules="fullname"/>
                  <app-text-input md="6" sm="12"
                    :label="$t('attributes.login_id')"
                    name="login_id"
                    prepend-icon="mdi-account"
                    rules="required"
                    v-model="formData.login_id"/>
                  <app-password-input md="6" sm="12"
                    :append-icon="passwordTypeIcon"
                    :label="$t('attributes.password')"
                    :type="passwordType"
                    @click:append="showPassword = !showPassword"
                    name="password" autocomplete="new-password"
                    prepend-icon="mdi-lock"
                    rules="required"
                    v-model="formData.password"/>
                  <app-password-input md="6" sm="12"
                    v-model="formData.password_confirmation"
                    name="password_confirmation"
                    rules="confirmed:password"/>
                  <app-text-input md="6" sm="12"
                      v-model="formData.email"
                      :label="$t('attributes.email')"
                      name="email"
                      rules="required"
                      prepend-icon="mdi-email"/>
                  <app-text-input md="6" sm="12"
                    v-model="formData.mobile"
                    :label="$t('attributes.mobile')"
                    name="mobile"
                    prepend-icon="mdi-cellphone"
                    v-on:keydown.enter="register"
                    rules="required|mobile"/>
                </div>
              </v-card-text>
              <v-divider/>
              <v-card-actions>
                <v-spacer></v-spacer>
                <app-submit min-width="120" :loading="loading" tooltip="register">{{ $t('register') }}</app-submit>
                <app-btn :loading="loading" min-width="120" tooltip="login" @click="SwitchModal">{{ $t('login') }}</app-btn>
              </v-card-actions>
            </v-card>
          </app-form>
        </v-col>
      </app-row>
    </v-dialog>
    <v-dialog scrollable :width="AppIsMobile?`100%`:`70%`" v-model="LoginDialog" v-if="LoginDialog">
      <app-row class="ma-0" width="100%" style="background: #f9f9f9;" align="center" justify="center">
        <v-col class="pa-0" style="background: #f9f9f9;" cols="12">
          <app-form :errors="errors" :submit="login" ref="form" v-slot="v">
            <v-card elevation="12" tile>
              <v-toolbar color="primary darken-2" dark flat>
                <v-toolbar-title>{{ $t('login') }}</v-toolbar-title>
              </v-toolbar>
              <v-card-text md="6" sm="6" xs="6">
                <app-text-input md="6" sm="12" xs="12"
                                :label="$t('attributes.login_id')"
                                name="login_id"
                                prepend-icon="mdi-account"
                                rules="required" v-if="Show_User_Password"
                                v-model="form.login_id"/>
                <app-text-input md="6" sm="12" xs="12"
                                :label="$t('attributes.email')"
                                name="email"
                                rules="required|email" v-if="!Show_User_Password"
                                v-model="email"/>
                <app-text-input v-on:keydown.enter="login"
                                :append-icon="passwordTypeIcon"
                                :label="$t('attributes.password')"
                                :type="passwordType"
                                @click:append="showPassword = !showPassword"
                                name="password" md="6" sm="6" xs="6"
                                prepend-icon="mdi-lock"
                                rules="required" v-if="Show_User_Password"
                                v-model="form.password"/>
              </v-card-text>
              <app-row width="100%" class="pa-0 ma-0">
                <app-col md="2" sm="1" xs="12" class="pa-0 ma-0 text-center text-md-center text-sm-center"></app-col>
                <app-col md="4" sm="11" xs="12" class="pa-0 ma-0 text-center text-md-center text-sm-center">
                  <v-card-text class="pa-0 ma-0 pointer" @click="ForgotPassword">
                    {{$t('attributes.forgot_password')}}
                  </v-card-text>
                </app-col>
                <app-col md="4" sm="12" xs="12" class="pa-0 ma-0 text-center text-md-center text-sm-center">
                  <v-card-text class="pa-0 ma-0 pointer" @click="ForgotUser">
                    {{$t('attributes.forgot_user')}}
                  </v-card-text>
                </app-col>
                <app-col md="2" sm="1" xs="12" class="pa-0 ma-0 text-center text-md-center text-sm-center"></app-col>
              </app-row>
              <v-card-actions>
                <app-btn :loading="loading" v-if="Show_User_Password" @click="login">{{$t('login') }}</app-btn>
                <app-btn :loading="loading" v-if="Forgot_Password&&!Show_User_Password" @click="ForgotPasswordClick">{{ $t('cancel') }}</app-btn>
                <app-btn :loading="loading" v-if="Forgot_Password&&!Show_User_Password" @click="ForgotPasswordSubmit">{{ $t('attributes.forgot_password_send') }}</app-btn>
                <app-btn :loading="loading" v-if="Forgot_User&&!Show_User_Password" @click="ForgotUserClick">{{ $t('cancel') }}</app-btn>
                <app-btn :loading="loading" v-if="Forgot_User&&!Show_User_Password" @click="ForgotUserSubmit">{{ $t('attributes.forgot_user_send') }}</app-btn>
                <v-spacer></v-spacer>
                <app-btn :loading="loading" v-if="Show_User_Password" @click="SwitchModal">{{ $t('register') }}</app-btn>
              </v-card-actions>
            </v-card>
          </app-form>
        </v-col>
      </app-row>
    </v-dialog>
  </v-app-bar>
</template>
<style>
.myred{
  background-color: #e21713 !important;
  border-color: #e21713 !important;
}
.myyell{
  background-color: #FFFF00FF !important;
  border-color: #FFFF00FF !important;
  color: #000000FF !important;
}
.mygreen{
  background-color: #006400FF !important;
  border-color: #006400FF !important;
}
.myorange{
  background-color: #FF8C00FF !important;
  border-color: #FF8C00FF !important;
}
.theme--light.v-btn--active:hover::before, .theme--light.v-btn--active::before {
  opacity: 0;
}
.theme--light.v-btn:hover::before {
  opacity: 0.08;
}
</style>
<script>
import GlobalMixins from '@mixins/GlobalMixins';
export default {
  name: 'Bar',
  data() {
    return {
      LoginDialog: !1,
      RegisterDialog: !1,
      LoadingDialog: !1,
      Show_User_Password:!0,
      Forgot_Password: !1,
      Forgot_User: !1,
      showPassword: !1,
      form: {
        login_id: null,
        password: null
      },
      formData: {},
      errors: {},
      loading: !1,
      UserNotifs: 0,
    }
  },
  mixins: [GlobalMixins],
  methods:{
    register() {
      localStorage.removeItem('current_user');
      localStorage.removeItem('perms');
      localStorage.removeItem('backend_routes');
      localStorage.removeItem(this.localstorageKey);
      this.loading = !0;
      return this.$axios.post('register', this.formData)
        .then(({data}) => {
          if (data && data.token) {
            this.alertSuccess(this.$t('messages.register_success'))
            this.loginUser(data.token, data.data || {}, true)
          }
          else
            this.loading = !1
        })
        .catch(({response}) => {
          const {data} = response || {}
          if (data) {
            this.errors = data.errors || []
            this.alertError(data.message)
          }
          this.loading = !1
        })
    },
    LoginModalActivatior(){
      localStorage.removeItem('current_user');
      localStorage.removeItem('perms');
      localStorage.removeItem('backend_routes');
      localStorage.removeItem(this.localstorageKey);
      this.LoginDialog = !this.LoginDialog;
    },
    RegisterModalActivatior(){
      localStorage.removeItem('current_user');
      localStorage.removeItem('perms');
      localStorage.removeItem('backend_routes');
      localStorage.removeItem(this.localstorageKey);
      this.RegisterDialog = !this.RegisterDialog;
    },
    SwitchModal(){
      localStorage.removeItem('current_user');
      localStorage.removeItem('perms');
      localStorage.removeItem('backend_routes');
      localStorage.removeItem(this.localstorageKey);
      this.RegisterDialog = !this.RegisterDialog;
      this.LoginDialog = !this.LoginDialog;
    },
    clickme(){
      return this.$router.push('/home');
    },
    logout() {
      this.confirm(this.$t('messages.sure_logout'), () => {
        this.logoutUser(!0);
      });
    },
    ForgotPasswordClick() {
      this.form.password = null;
      this.form.login_id = null;
      this.email = null;
      this.Forgot_Password = !1;
      this.Forgot_User = !1;
      this.Show_User_Password = !0;
    },
    ForgotUserClick() {
      this.form.password = null;
      this.form.login_id = null;
      this.email = null;
      this.Forgot_Password = !1;
      this.Forgot_User = !1;
      this.Show_User_Password = !0;
    },
    ForgotPassword() {
      this.form.password = null;
      this.form.login_id = null;
      this.email = null;
      this.Forgot_User = !1;
      this.Show_User_Password = !1;
      this.Forgot_Password = !0;
    },
    ForgotPasswordSubmit() {
      if(!this.email||this.email===''||this.email===null){return;}
      this.$axios.post(`forgot-password`, {email: this.email,})
        .then(data => {
          if (data.data && data.data.success) {
            this.alertSuccess(data.data.message);
            this.$nextTick(() => {
              this.Forgot_Password = !1;
              this.Forgot_User = !1;
              this.Show_User_Password = !0;
            });
          }
          else if(data.data && !data.data.success){
            this.alertError(data.data.message);
          }
        }).catch(({response}) => {
        const {data} = response || {};
        data && data.message && this.alertError(data.message) && data.errors;
        console.log(data.data);
      });
    },
    ForgotUser() {
      this.form.password = null;
      this.form.login_id = null;
      this.email = null;
      this.Forgot_Password = !1;
      this.Forgot_User = !this.Forgot_User;
      this.Show_User_Password = !1;
    },
    ForgotUserSubmit() {
      if(!this.email||this.email===''||this.email===null){return;}
      this.$axios.post(`forgot-user`, {email: this.email,})
        .then(data => {
          if (data.data && data.data.success) {
            this.alertSuccess(data.data.message);
            this.$nextTick(() => {
              this.email = null;
              this.Forgot_Password = !1;
              this.Forgot_User = !1;
              this.Show_User_Password = !0;
            });
          }
          else if(data.data && !data.data.success){
            this.alertError(data.data.message);
          }
        }).catch(({response}) => {
        const {data} = response || {};
        data && data.message && this.alertError(data.message) && data.errors;
        console.log(data.data);
      });
    },
    login() {
      localStorage.removeItem('current_user');
      localStorage.removeItem('perms');
      localStorage.removeItem('backend_routes');
      localStorage.removeItem(this.localstorageKey);
      this.loading = !0;
      return this.$api.methods.auth.login(this.form)
        .then(({data}) => {
          if (data && data.token) {
            this.loginUser(data.token, data.data || {}, true)
          }
          else
            this.loading = !1
        })
        .catch(({response}) => {
          const {data} = response || {}
          if (data) {
            this.errors = data.errors || []
            this.alertError(data.message)
          }
          this.loading = !1
        })
    },
  },
  props: {
    value: {
      type: Boolean
    },
    source: String,
  },
  computed: {
    userComp: {
      get() {
        return this.$root.authUser || {};
      },
      set(v) {
        this.$root.authUser = v;
      }
    },
    activeClasses() {
      return [
        'active-item',
        'myred',
        'white--text',
        'darken-3',
      ].join(' ');
    },
    getsize(){
      if(this.AppIsMobile){
        return "dense";
      }
      return "x-large";
    },
    getsize2(){
      if(this.AppIsMobile){
        return "22";
      }
      return "22";
    },
    logoColor() {
      return this.themeDark ? 'gray' : 'transparent';
    },
    showDrawerIcon() {
      return true;
    },
    drawer: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('input', Boolean(v));
      }
    },
    user() {
      return this.$root.authUser || {};
    },
    GetUserNotifs(){
      this.UserNotifs = 0;
      if(this.userComp.identities_waiting_user_action_counter>0){
        this.UserNotifs = this.UserNotifs+Number(this.userComp.identities_waiting_user_action_counter);
      }
      if(this.userComp.orders_waiting_shippment_info_counter>0){
        this.UserNotifs = this.UserNotifs+Number(this.userComp.orders_waiting_shippment_info_counter);
      }
      if(!this.userComp.mobile_verification){
        this.UserNotifs = this.UserNotifs+1;
      }
      if(this.userComp.notifications_count>0){
        this.UserNotifs = this.UserNotifs+Number(this.userComp.notifications_count);
      }

      return this.UserNotifs;
    },
    passwordType() {
      return this.showPassword ? 'text' : 'password'
    },
    passwordTypeIcon() {
      return this.showPassword ? 'mdi-eye-off' : 'mdi-eye'
    },
  }
};
</script>
