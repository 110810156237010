

import API_CONFIG from '@/app/api-config';
import {defaultLocale} from '@/app/config';
import Auth from '@helpers/auth';
import routeAllies from '@routes/config';
import axios from 'axios';
import Qs from 'qs';
import Vue from 'vue';

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
let token;
if ((token = document.head.querySelector('meta[name=\'csrf-token\']'))) {
  axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
} else {
  console.error('MHF token not found');
}

axios.defaults.paramsSerializer = params => Qs.stringify(params, {arrayFormat: 'indices'});

// console.log(axios.defaults);

axios.interceptors.response.use(function (response) {
  const {data} = response || {};

  if (data) {
    response.result = data.data;
    if (data.message) {
      response.resultMessage = data.message
    }
  }
  return response;
}, function (error) {

  const {response} = error || {};
  const status = response && response.status || null;
  let url;
  if (status === 401) {
    this.alertError(this.$t('messages.error_401'));
  }
  if (status === 403) {
    this.alertError(this.$t('messages.error_403'));
  }
  if (response && response.data) {
    error.responseError = response.data;
  }
  return Promise.reject(error);
});

Vue.prototype.$axios2 = axios;
window.axios2 = axios;

export default axios;
