

export default {
  areYouSure: 'Are you sure?',
  sure_logout: 'Are you sure the account will be logged out?',
  exportAll: 'Are you sure to export all data?',
  confirmDelete: 'Are you sure to delete data?',
  error: 'An error occurred, please try again later.',
  whoops: 'Whoops',
  not_found_message: 'The request not found',
  window_blocked: 'Please make sure to allow pop-ups in your browser to be able to download files. It is recommended to use the Google Chrome browser for all the services and features of the program.',
  sessionExpired: 'The session has expired the page will be refreshed',
  formWasChanged: 'The data was modify',
  log_out_successfully:       'Logged Out successfully!!',
  success:                    'Successfully!!',
  store_success:              'Added successfully',
  updated_success:            'The data has been modified successfully',
  deleted_success:            'The data has been deleted successfully',
  deleted_failed:             'Deleted Failed !!',
  coupon_error:               'Invalid/Expired coupon !!',
  coupon_failed:              'Cannot use coupon more than once !!',
  error_500:                  'Something wrong !!',
  error_429:                  'Cannot complete connection. Too many attempts. Please try again later !!',
  error_422:                  'The Given Data Was Invalid !!',
  error_404:                  'Page not found !!',
  error_403:                  'This page cannot be reached !!',
  error_401:                  'Something went wrong. Please sign in !!',
  error_model_not_found:      'Data not found !!',
  no_data:                    'There is no data in the uploaded file or your data format does not match our import template !!',
  Hello:                      'Hello - مرحبا',
  user_sent:                  'Username has been sent by Email',
  reset_sent:                 'Password reset email sent',
  reset_nosent:               'Password reset email error !!',
  reset_success:              'Your account password has been reset successfully',
  reset_nosuccess:            'Password reset email error !!',
  the_given_data_was_invalid: 'The Given Data Was Invalid !!',
}
