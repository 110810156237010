

<template>
  <div class="mb-4">
    <v-toolbar dense dark flat color="light-blue darken-4" elevation="10">
      <v-toolbar-title>
        <slot></slot>
      </v-toolbar-title>
    </v-toolbar>
    <v-divider class="mt-3 mb-2"></v-divider>
  </div>
</template>

<script>
export default {
  name: "PageTitle"
}
</script>

<style scoped>

</style>
