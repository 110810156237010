/*
  SarhSoft Cross-PlatForm Engine Project
  Lnaguages: PHP, JavaScript, Node, Java.
  BackEnd: Laravel.
  FrontEnd: Vue2.
  Project Type: (Laravel,Vue Mixin) Java Cross Frame.
  Written By: M.Farhat,
  Email: m.farhat@sarhsoft.com.
  Copyright © 2022 SarhSoft All rights reserved.
*/
export default {
    dealer_type: 'Dealer Type',
    contact_number: 'Contact Number',
    region: 'Region',
    latitude: 'Latitude',
    longitude: 'Longitude',
    note: 'Note',
    status: 'Status',
    dealer_type0: 'Un Defined',
    dealer_type1: 'Lubricants',
    dealer_type2: 'Spare Parts',
    region0: 'Un Defined',
    region1: 'Central Area',
    region2: 'Eastern Area',
    region3: 'Western Area',
    region4: 'Northern Area',
    region5: 'Southern Area',
    main_city_id: 'City',
};
