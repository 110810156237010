<template>
  <v-list dense>
    <template v-for="(item, i) in items">
      <app-list-item :item="item"></app-list-item>
    </template>
    <v-divider v-if="isLogin"></v-divider>
    <v-list-item v-if="isLogin" class="px-2" link two-line @click.stop="logout">
      <v-list-item-avatar>
        <v-icon>mdi-logout</v-icon>
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-action-text v-text="$t('logout')">
        </v-list-item-action-text>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  name: "DrawerList",
  props: ['items'],

  data() {
    return {
      logoutLoading: !1,
    }
  },
  methods:{
    logout() {
      // this.setDrawer(!1)
      this.logoutLoading = !0
      this.$emit('drawer', false)
      this.confirm(this.$t('messages.sure_logout'), () => {
        this.$api.methods.auth.logout().finally(() => this.logoutUser(true))
      }, () => {
        this.logoutLoading = !1
      })
    }
  }
}
</script>
