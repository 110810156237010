<template>
  <input
      :id="inputName"
      :value="inputValue"
      :placeholder="'Input: '+ inputName"
      @focus="onInputFocus"
      @input="onInputChange">
</template>
<script>
export default {
  name: "Input",
  props: {
    inputs: {
      type: Object
    },
    inputName: {
      type: String
    }
  },
  methods: {
    onInputChange(input) {
      this.$emit("onInputChange", input);
    },
    onInputFocus(input) {
      this.$emit("onInputFocus", input);
    }
  },
  computed: {
    inputValue: function () {
      return this.inputs[this.inputName];
    }
  }
};
</script>
<style scoped>
</style>
