



<template>
    <v-progress-linear
        :active="$root.AppProgress"
        indeterminate
        v-bind="$attrs"
        v-on="$listeners"
        fixed
        class="mb-1"
    ></v-progress-linear>
</template>

<script>
export default {
  name: "AppProgressLinear"
}
</script>

<style scoped>

</style>
