/*
  SarhSoft Cross-PlatForm Engine Project
  Lnaguages: PHP, JavaScript, Node, Java.
  BackEnd: Laravel.
  FrontEnd: Vue2.
  Project Type: (Laravel,Vue Mixin) Java Cross Frame.
  Written By: M.Farhat,
  Email: m.farhat@sarhsoft.com.
  Copyright © 2022 SarhSoft All rights reserved.
*/
export default {
    dealer_type: 'نوع الموزع',
    contact_number: 'رقم التواصل',
    region: 'المنطقة',
    latitude: 'خط الطول',
    longitude: 'خط العرض',
    note: 'ملاحظة',
    main_city_id: 'المدينة',
    dealer_type0: 'غير محدد',
    dealer_type1: 'زيوت',
    dealer_type2: 'قطع غيار',
    region0: 'غير محدد',
    region1: 'المنطقة الوسطى',
    region2: 'المنطقة الشرقية',
    region3: 'المنطقة الغربية',
    region4: 'المنطقة الشمالية',
    region5: 'المنطقة الجنوبية',
};
