export default {
  product_id: 'Product',
  verfied_user_identity: 'Verfied User Identity',
  country_id: 'Country',
  city_id: 'City',
  order_id: 'Order No',
  city_bassami: 'Bassami Branch',
  nationality_id: 'Nationality',
  identity_type: 'Identity Type',
  identity_type1: 'National ID',
  identity_type2: 'Residency ID',
  user_type: 'Account Type',
  user_type0: 'Undefined',
  user_type1: 'Individual User ',
  user_type2: 'Individual Trader',
  user_type3: 'Company',
  user_status: 'Account Status',
  user_status1: 'New / Waiting validation',
  user_status2: 'Active',
  user_status3: 'Suspended',
  order_status: 'Order Status',
  totals: 'Order Amount',
  recipient_name: 'Recipient Name at (Bassami)',
  recipient_mobile: 'Recipient Mobile at (Bassami)',
  temp_plate: 'Temporary Plate (Customs)',
  fasah_auth: 'Fasah Authrize Code',
  refund_name: 'Consumer name',
  refund_iban: 'IBan',
  refund_bank: 'Bank name',
};
