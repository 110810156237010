

<template>
  <app-tooltip
      :disabled="tooltipDisabled"
      :text="getTooltip"
      :min-width="minWidth"
      :min-height="minHeight"
  >
    <v-btn
        v-bind="$attrs"
        v-on="$listeners"
        :class="getClasses"
        :min-width="minWidth"
        :min-height="minHeight"
        fab
        small
    >
      <template v-if="update"><v-icon color="success">mdi-pencil</v-icon></template>
      <template v-if="show"><v-icon color="primary">mdi-eye</v-icon></template>
      <template v-if="destroy"><v-icon color="error">mdi-delete</v-icon></template>
      <template v-if="upload"><v-icon color="primary">mdi-file-upload</v-icon></template>
      <template v-if="convert"><v-icon color="primary">mdi-account-convert</v-icon></template>
      <template v-if="refresh"><v-icon color="success">mdi-refresh</v-icon></template>
      <template v-if="refuse"><v-icon color="error">mdi-account-cancel</v-icon></template>
      <template v-if="cancel"><v-icon color="error">mdi-cancel</v-icon></template>
      <template v-if="approve"><v-icon color="success">mdi-location-exit</v-icon></template>
      <template v-if="completed"><v-icon color="success">mdi-location-exit</v-icon></template>
      <template v-if="refund"><v-icon color="error">mdi-location-exit</v-icon></template>
      <template v-if="move"><v-icon color="primary">mdi-bank-transfer</v-icon></template>
      <template v-if="payment"><v-icon color="error">mdi-cash-plus</v-icon></template>
      <template v-if="shippment"><v-icon color="error">mdi-home-import-outline</v-icon></template>
      <slot></slot>
    </v-btn>
  </app-tooltip>
</template>
<script>
export default {
  name: 'Btn',
  props: {
    minWidth: {
      type: [String, Number],
      default: () => undefined
    },
    minHeight: {
      type: [String, Number],
      default: () => undefined
    },
    update: {
      type: Boolean,
      default: () => false
    },
    updateaddtext: {
      type: String,
      default: () => ''
    },
    show: {
      type: Boolean,
      default: () => false
    },
    showaddtext: {
      type: String,
      default: () => ''
    },
    destroy: {
      type: Boolean,
      default: () => false
    },
    destroyaddtext: {
      type: String,
      default: () => ''
    },
    upload: {
      type: Boolean,
      default: () => false
    },
    uploadaddtext: {
      type: String,
      default: () => ''
    },
    convert: {
      type: Boolean,
      default: () => false
    },
    convertaddtext: {
      type: String,
      default: () => ''
    },
    refresh: {
      type: Boolean,
      default: () => false
    },
    refreshaddtext: {
      type: String,
      default: () => ''
    },
    refuse: {
      type: Boolean,
      default: () => false
    },
    refuseaddtext: {
      type: String,
      default: () => ''
    },
    cancel: {
      type: Boolean,
      default: () => false
    },
    canceladdtext: {
      type: String,
      default: () => ''
    },
    approve: {
      type: Boolean,
      default: () => false
    },
    approveaddtext: {
      type: String,
      default: () => ''
    },
    completed: {
      type: Boolean,
      default: () => false
    },
    completedaddtext: {
      type: String,
      default: () => ''
    },
    refund: {
      type: Boolean,
      default: () => false
    },
    refundaddtext: {
      type: String,
      default: () => ''
    },
    move: {
      type: Boolean,
      default: () => false
    },
    moveaddtext: {
      type: String,
      default: () => ''
    },
    payment: {
      type: Boolean,
      default: () => false
    },
    paymentaddtext: {
      type: String,
      default: () => ''
    },
    shippment: {
      type: Boolean,
      default: () => false
    },
    shippmentaddtext: {
      type: String,
      default: () => ''
    },
    tooltip: {
      default: () => undefined
    }
  },
  computed: {
    getClasses: () => [
      'app-dt-btn',
      'my-2'
    ].join(' '),
    getMinWidth() {
      return this.$attrs.icon === undefined ? this.minWidth : undefined;
    },
    getMinHeight() {
      return this.$attrs.icon === undefined ? this.minHeight : undefined;
    },
    getTooltip() {
      if(this.tooltip) return this.parseAttribute(this.tooltip);
      if(this.update) return this.$t('update')+this.updateaddtext;
      if(this.show) return this.$t('show')+this.showaddtext;
      if(this.destroy) return this.$t('destroy')+this.destroyaddtext;
      if(this.upload) return this.$t('upload')+this.uploadaddtext;
      if(this.convert) return this.parseAttribute('convert')+this.convertaddtext;
      if(this.refresh) return this.parseAttribute('refresh')+this.refreshaddtext;
      if(this.refuse) return this.parseAttribute('refuse')+this.refuseaddtext;
      if(this.cancel) return this.parseAttribute('cancel')+this.canceladdtext;
      if(this.approve) return this.parseAttribute('approve')+this.approveaddtext;
      if(this.completed) return this.parseAttribute('completed')+this.completedaddtext;
      if(this.refund) return this.parseAttribute('refund')+this.completedaddtext;
      if(this.payment) return this.parseAttribute('payment')+this.paymentaddtext;
      if(this.shippment) return this.parseAttribute('shippment_info')+this.shippmentaddtext;
      if(this.move) return this.$t('move')+this.moveaddtext;

      return null;
    },
    tooltipDisabled() {
      return this.getTooltip === null || this.getTooltip === undefined;
    }
  }
};
</script>
