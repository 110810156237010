

<template>
  <app-tooltip :disabled="tooltipDisabled" :text="getTooltip">
    <BaseBtn
        v-bind="$attrs"
        v-on="$listeners"
        class="ma-0 pa-0 px-2"
        :color="getBTNColor?getBTNColor:'primary'"
        pHeight="30">
      {{ getTooltip }}
      <slot></slot>
    </BaseBtn>
  </app-tooltip>
</template>

<script>
import BaseBtn from '@components/base/btn/BaseBtn';

export default {
  name: 'Btn',
  components: {BaseBtn},
  props: {
    update: {
      type: Boolean,
      default: () => false
    },
    updateaddtext: {
      type: String,
      default: () => ''
    },
    show: {
      type: Boolean,
      default: () => false
    },
    showaddtext: {
      type: String,
      default: () => ''
    },
    destroy: {
      type: Boolean,
      default: () => false
    },
    destroyaddtext: {
      type: String,
      default: () => ''
    },
    upload: {
      type: Boolean,
      default: () => false
    },
    uploadaddtext: {
      type: String,
      default: () => ''
    },
    convert: {
      type: Boolean,
      default: () => false
    },
    convertaddtext: {
      type: String,
      default: () => ''
    },
    refresh: {
      type: Boolean,
      default: () => false
    },
    refreshaddtext: {
      type: String,
      default: () => ''
    },
    refuse: {
      type: Boolean,
      default: () => false
    },
    refuseaddtext: {
      type: String,
      default: () => ''
    },
    cancel: {
      type: Boolean,
      default: () => false
    },
    canceladdtext: {
      type: String,
      default: () => ''
    },
    approve: {
      type: Boolean,
      default: () => false
    },
    approveaddtext: {
      type: String,
      default: () => ''
    },
    completed: {
      type: Boolean,
      default: () => false
    },
    completedaddtext: {
      type: String,
      default: () => ''
    },
    refund: {
      type: Boolean,
      default: () => false
    },
    refundaddtext: {
      type: String,
      default: () => ''
    },
    move: {
      type: Boolean,
      default: () => false
    },
    moveaddtext: {
      type: String,
      default: () => ''
    },
    payment: {
      type: Boolean,
      default: () => false
    },
    paymentaddtext: {
      type: String,
      default: () => ''
    },
    shippment: {
      type: Boolean,
      default: () => false
    },
    shippmentaddtext: {
      type: String,
      default: () => ''
    },
    tooltip: {
      default: () => undefined
    }
  },
  computed: {
    getTooltip() {
      if(this.tooltip) return this.parseAttribute(this.tooltip);
      if(this.update) return this.$t('update')+this.updateaddtext;
      if(this.show) return this.$t('show')+this.showaddtext;
      if(this.destroy) return this.$t('destroy')+this.destroyaddtext;
      if(this.upload) return this.$t('upload')+this.uploadaddtext;
      if(this.convert) return this.parseAttribute('convert')+this.convertaddtext;
      if(this.refresh) return this.parseAttribute('refresh')+this.refreshaddtext;
      if(this.refuse) return this.parseAttribute('refuse')+this.refuseaddtext;
      if(this.cancel) return this.parseAttribute('cancel')+this.canceladdtext;
      if(this.approve) return this.parseAttribute('approve')+this.approveaddtext;
      if(this.completed) return this.parseAttribute('completed')+this.completedaddtext;
      if(this.refund) return this.parseAttribute('refund')+this.completedaddtext;
      if(this.payment) return this.parseAttribute('payment')+this.paymentaddtext;
      if(this.shippment) return this.parseAttribute('shippment_info')+this.shippmentaddtext;
      if(this.move) return this.$t('move')+this.moveaddtext;

      return null;
    },
    getBTNColor() {
      if(this.tooltip)   return "primary";
      if(this.update)    return "success";
      if(this.show)      return "primary";
      if(this.destroy)   return "error";
      if(this.upload)    return "primary";
      if(this.convert)   return "primary";
      if(this.refresh)   return "success";
      if(this.refuse)    return "error";
      if(this.cancel)    return "error";
      if(this.approve)   return "success";
      if(this.completed) return "success";
      if(this.refund)    return "error";
      if(this.payment)   return "primary";
      if(this.shippment) return "error";
      if(this.move)      return "error";

      return null;
    },
    tooltipDisabled() {
      return this.getTooltip === null || this.getTooltip === undefined;
    }
  }
};
</script>
