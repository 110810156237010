<template>
  <v-system-bar class="pa-0" style="background: rgb(42 54 134);" :dark="themeLight" :height="!this.AppIsMobile?35:35" app>
    <v-row class="pa-0 ma-0">
      <v-col cols="6" class="pa-0 ma-0 ps-3">
        <v-row class="pa-0 ma-0 justify-end-start">
          <a target="_blank" href="https://wa.me/821076188701"><v-icon class="ms-0 me-1">mdi-whatsapp</v-icon></a>00821076188701<a href="tel:00821076188701"><v-icon class="ms-1 me-0">mdi-phone</v-icon></a>
        </v-row>
      </v-col>
      <v-col cols="6" class="pa-0 ma-0">
        <v-row class="pa-0 ma-0 justify-end pe-3">
      <v-icon class="ms-0 me-1">mdi-email</v-icon> <a href="mailto:info@alqassimcars.com" style="color: #ffffffb3;">info@alqassimcars.com</a>
        </v-row>
      </v-col>
    </v-row>
    <v-spacer v-if="!this.AppIsMobile"></v-spacer>
<!--    <span v-if="this.AppIsMobile">&nbsp;&nbsp;</span>-->
<!--    <v-btn class="app-dt-btn my-2" max-width="25" max-height="25" fab small target="_blank" href="https://twitter.com/alqassim_cars"-->
<!--           style="background: #ffffffb3;" icon :title="$t('twitter')">-->
<!--      <v-icon style="margin-right: 0;color: rgb(42 54 134);">mdi-twitter</v-icon>-->
<!--    </v-btn>&nbsp;&nbsp;-->
<!--    <v-btn class="app-dt-btn my-2" max-width="25" max-height="25" fab small target="_blank" href="https://facebook.com/alqassim.cars"-->
<!--           style="background: #ffffffb3;" icon :title="$t('facebook')">-->
<!--      <v-icon style="margin-right: 0;color: rgb(42 54 134);">mdi-facebook</v-icon>-->
<!--    </v-btn>&nbsp;&nbsp;-->
<!--    <v-btn class="app-dt-btn my-2" max-width="25" max-height="25" fab small target="_blank" href="https://snapchat.com/add/alqassim.cars"-->
<!--           style="background: #ffffffb3;" icon :title="$t('snapchat')">-->
<!--      <v-icon style="margin-right: 0;color: rgb(42 54 134);">mdi-snapchat</v-icon>-->
<!--    </v-btn>&nbsp;&nbsp;-->
<!--    <v-btn class="app-dt-btn my-2" max-width="25" max-height="25" fab small target="_blank" href="https://instagram.com/alqassim.cars/"-->
<!--           style="background: #ffffffb3;" icon :title="$t('instagram')">-->
<!--      <v-icon style="margin-right: 0;color: rgb(42 54 134);">mdi-instagram</v-icon>-->
<!--    </v-btn>&nbsp;&nbsp;-->
<!--    <v-btn class="app-dt-btn my-2" max-width="25" max-height="25" fab small target="_blank" href="https://youtube.com/channel/UC8ba_qHDE8-k6qXfIbk6Jew"-->
<!--           style="background: #ffffffb3;" icon :title="$t('youtube')">-->
<!--      <v-icon style="margin-right: 0;color: rgb(42 54 134);">mdi-youtube</v-icon>-->
<!--    </v-btn>&nbsp;&nbsp;&nbsp;-->
<!--    <v-btn class="app-dt-btn my-2" max-width="25" max-height="25" fab small target="_blank" href="https://t.me/alqassim_cars"-->
<!--           style="background: #ffffffb3;" icon :title="$t('telegram')">-->
<!--      <v-icon style="margin-right: 0;color: rgb(42 54 134);">mdi-telegram</v-icon>-->
<!--    </v-btn>-->
    <v-spacer v-if="!this.AppIsMobile"></v-spacer>
<!--    <span v-if="this.AppIsMobile">&nbsp;&nbsp;</span>-->
    <template v-if="$root.canFullscreen && !$vuetify.breakpoint.xsOnly">
      <app-system-bar-btn class="me-2"
          v-if="!$root.AppFullscreen"
          @click="$root.setFullscreen(!0)">
        <v-icon>mdi-fullscreen</v-icon>
      </app-system-bar-btn>
      <app-system-bar-btn
          v-if="$root.AppFullscreen"
          @click="$root.setFullscreen(!1)">
        <v-icon>mdi-fullscreen-exit</v-icon>
      </app-system-bar-btn>
    </template>
  </v-system-bar>
</template>
<style>
.v-application a {
  color: transparent;
}
</style>
<script>

export default {
  name: 'SystemBar',
  data() {
    return {
      hijri: null,
      date: null,
      time: null,
      day: null,
      spanClass: 'px-3'
    };
  },
  computed: {
    getClasses() {
      return {
        primary: true,
        'darken-2': this.themeLight,
        'darken-1': this.themeDark
      };
    },
  },
  created() {
    let time, date, day, hijri;
    setInterval(() => {
      time = this.$moment().format('hh:mm:ss a');
      date = this.$moment().format('YYYY/MM/DD');
      day = this.$moment().format('ddd');
      hijri = this.$moment().format('iYYYY/iMM/iDD');

      this.date = `${date}`;
      this.hijri = `${hijri}`;
      this.time = time;
      this.day = day;
    }, 1000);
  },
  methods: {
    closeWindow() {
      this.confirm(this.$t('messages.sure_logout'), () => {
        // window.location.href = routeAllies.auth.logout;
        this.logoutUser(!0);
      });
    },
  },
};
</script>

<style scoped>
</style>
