
import {trimStart} from 'lodash'

export const API_CONFIG = {
  baseUrl: "/api",
  url(url) {
    return `${this.baseUrl}/${trimStart(url, "/")}`;
  },
  url2(url) {
    return `url`;
  },
};

export default API_CONFIG
