export default {
    nationality_id: 'Nationality',
    identity_type: 'Identity Type',
    identity_type1: 'National ID',
    identity_type2: 'Residency ID',
    identity_status: 'Identity Status',
    identity_status0: 'Waiting for Upload Identity photo',
    identity_status1: 'Under Review',
    identity_status2: 'Invalid Identity photo',
    identity_status3: 'Rejected Identity',
    identity_status4: 'Verified Identity',
    identity_status5: 'Uploaded Empty Identity photo',

};
