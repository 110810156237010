const Choicesfiles = require.context('./', true, /\.js$/);
let ChoicesConsts = {};
for (const Choicesfile of Choicesfiles.keys()) {
  if(
      Choicesfile.replace(/index.js/, '')
               .replace(/.js/, '')
               .replace(/^\.\//, '')
               .startsWith('choices_')
  ){
    ChoicesConsts = {...ChoicesConsts, ...(Choicesfiles(Choicesfile).default || Choicesfiles(Choicesfile))};
  }
}
// console.log(ChoicesConsts);
export default ChoicesConsts;
