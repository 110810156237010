export default {
    name: 'name',
    namear: 'namear',
    role: 'الصلاحيات',
    role_id: 'الصلاحيات',
    login_id: 'login_id',
    password: 'password',
    remember_token: 'remember_token',
    email_verified_at: 'email_verified_at',
    email: 'email',
    language: 'language',
    status: 'status',
    mobile: 'mobile',

};
