

export default {
  areYouSure: 'هل أنت متأكد؟',
  sure_logout: 'هل أنت متأكد سوف يتم تسجيل الخروج من الحساب',
  exportAll: 'هل أنت متأكد من تصدير جميع البيانات؟',
  confirmDelete: 'هل أنت متأكد من حذف البيانات؟',
  error: 'حدث خطأ الرجاء المحاولة لاحقاً',
  whoops: 'عذراً',
  not_found_message: 'لم يتم العثور على البيانات المطلوبة',
  window_blocked: 'الرجاء التأكد من سماح فتح النوافذ المنبثقه في المتصفح لتستطيع تحميل الملفات. ينصح باستخدام متصفح قوقل كروم (Google Chrome) للحصول على جميع الخدمات والمميزات في البرنامج.',
  sessionExpired: 'تم انتهاء صلاحية الجلسة سوف يتم تحديث الصفحة',
  formWasChanged: 'تم تعديل البيانات',
  register_success: 'تم تسجيل حسابك بنجاح',
  false: 'معطل',
  true: 'مفعل',
  log_out_successfully: 'تم تسجيل الخروج بنجاح',
  success: 'تم بنجاح',
  store_success: 'تمت الإضافة بنجاح',
  updated_success: 'تم التعديل بنجاح',
  deleted_success: 'تم الحذف بنجاح',
  deleted_failed: 'فشلت عملية الحذف',
  coupon_error: 'الكوبون المستخدم (غير صالح/منتهي الصلاحية)',
  coupon_failed: 'لا يمكنك استخدام الكوبون أكثر من مرة',
  error_500: 'حدث خطأ غير متوقع !!',
  error_429: 'لا يمكن اتمام الاتصال يوجد عدد كبير جدا من المحاولات، الرجاء المحاولة لاحقاً',
  error_422: 'الرجاء التأكد من جميع البيانات',
  error_404: 'لم يتم العثور على الصفحة المطلوبة',
  error_403: 'لا يمكن الوصول الى الصفحة المطلوبة',
  error_401: 'الرجاء تسجيل الدخول مرة أخرى',
  error_model_not_found: 'لم يتم العثور على البيانات المطلوبة !!',
  no_data: 'لا يوجد بيانات بالملف المرفق او صيغة البيانات لا تتوافق مع قالب الاستيراد !!',
  Hello: 'Hello - مرحبا',
  user_sent: 'تم ارسال اسم المستخدم لبريدك الإلكتروني',
  reset_sent: 'تم ارسال ارشادات تغيير كلمة المرور لبريدك الإلكتروني',
  reset_nosent:'خطأ في بريدك الإلكتروني !!',
  reset_success:'تم إعادة تعيين كلمة المرور الخاصة بحسابك !!',
  reset_nosuccess:'خطأ في بريدك الإلكتروني !!',
  the_given_data_was_invalid: 'الرجاء التأكد من جميع البيانات',
}
