

import * as VueGoogleMaps from "vue2-google-maps";
import Vue from 'vue';

Vue.config.productionTip = false;

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyCy5GXLJ3tRDDWNswlx9EIODkDVsLDiZdI",
    autobindAllEvents: true,
  },
  installComponents: true,
});

export default VueGoogleMaps;
