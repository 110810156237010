<template>
  <v-dialog scrollable :width="this.AppIsMobile?`100%`:`70%`" v-model="show">
    <app-row class="ma-0" width="100%" style="background: #f9f9f9;" align="center" justify="center">
      <v-col class="pa-0" style="background: #f9f9f9;" cols="12">
        <app-form :errors="errors" :submit="login" ref="form" v-slot="v">
          <v-card elevation="12" tile>
            <v-toolbar color="primary darken-2" dark flat>
              <v-toolbar-title>{{ $t('login') }}</v-toolbar-title>
            </v-toolbar>
            <v-card-text md="6" sm="6" xs="6">
              <app-text-input md="6" sm="12" xs="12"
                              :label="$t('attributes.login_id')"
                              name="login_id"
                              prepend-icon="mdi-account"
                              rules="required" v-if="Show_User_Password"
                              v-model="form.login_id"/>
              <app-text-input md="6" sm="12" xs="12"
                              :label="$t('attributes.email')"
                              name="email"
                              rules="required|email" v-if="!Show_User_Password"
                              v-model="email"/>
              <app-text-input v-on:keydown.enter="login"
                              :append-icon="passwordTypeIcon"
                              :label="$t('attributes.password')"
                              :type="passwordType"
                              @click:append="showPassword = !showPassword"
                              name="password" md="6" sm="6" xs="6"
                              prepend-icon="mdi-lock"
                              rules="required" v-if="Show_User_Password"
                              v-model="form.password"/>
            </v-card-text>
            <app-row width="100%" class="pa-0 ma-0">
              <app-col md="2" sm="1" xs="12" class="pa-0 ma-0 text-center text-md-center text-sm-center"></app-col>
              <app-col md="4" sm="11" xs="12" class="pa-0 ma-0 text-center text-md-center text-sm-center">
                <v-card-text class="pa-0 ma-0 pointer" @click="ForgotPassword">
                  {{$t('attributes.forgot_password')}}
                </v-card-text>
              </app-col>
              <app-col md="4" sm="12" xs="12" class="pa-0 ma-0 text-center text-md-center text-sm-center">
                <v-card-text class="pa-0 ma-0 pointer" @click="ForgotUser">
                  {{$t('attributes.forgot_user')}}
                </v-card-text>
              </app-col>
              <app-col md="2" sm="1" xs="12" class="pa-0 ma-0 text-center text-md-center text-sm-center"></app-col>
            </app-row>
            <v-card-actions>
              <app-btn :loading="loading" v-if="Show_User_Password" @click="login">{{$t('login') }}</app-btn>
              <app-btn :loading="loading" v-if="Forgot_Password&&!Show_User_Password" @click="ForgotPasswordClick">{{ $t('cancel') }}</app-btn>
              <app-btn :loading="loading" v-if="Forgot_Password&&!Show_User_Password" @click="ForgotPasswordSubmit">{{ $t('attributes.forgot_password_send') }}</app-btn>
              <app-btn :loading="loading" v-if="Forgot_User&&!Show_User_Password" @click="ForgotUserClick">{{ $t('cancel') }}</app-btn>
              <app-btn :loading="loading" v-if="Forgot_User&&!Show_User_Password" @click="ForgotUserSubmit">{{ $t('attributes.forgot_user_send') }}</app-btn>
              <v-spacer></v-spacer>
              <app-btn :loading="loading" v-if="Show_User_Password" @click="SetSwitch">{{ $t('register') }}</app-btn>
            </v-card-actions>
          </v-card>
        </app-form>
      </v-col>
    </app-row>
  </v-dialog>
</template>
<script>
export default {
  name: "LoginDialog",
  props: {
    loading: {
      type: [Number,Boolean],
      required: !0,
      default: () => false
    },
    show: {
      type: Boolean,
      required: !0
    },
    errors: {
      type: Object,
      required: !0,
      default: () => {}
    }
  },
  data() {
    return {
      email: null,
      Show_User_Password:!0,
      Forgot_Password: !1,
      Forgot_User: !1,
      showPassword: !1,
      form: {
        login_id: null,
        password: null
      },
    };
  },
  computed: {
    loadingData: {
      set(n) {
        this.$emit('update:loading', parseInt(n));
      },
      get() {
        return this.loading
      },
    },
    showData: {
      set(n) {
        this.$emit('update:show', n);
      },
      get() {
        return this.show
      },
    },
    passwordType() {
      return this.showPassword ? 'text' : 'password'
    },
    passwordTypeIcon() {
      return this.showPassword ? 'mdi-eye-off' : 'mdi-eye'
    },
  },
  created() {},
  methods: {
    SetSwitch() {
      this.$emit("set-switch", 1);
    },
    ForgotPasswordClick() {
      this.form.password = null;
      this.form.login_id = null;
      this.email = null;
      this.Forgot_Password = !1;
      this.Forgot_User = !1;
      this.Show_User_Password = !0;
    },
    ForgotUserClick() {
      this.form.password = null;
      this.form.login_id = null;
      this.email = null;
      this.Forgot_Password = !1;
      this.Forgot_User = !1;
      this.Show_User_Password = !0;
    },
    ForgotPassword() {
      this.form.password = null;
      this.form.login_id = null;
      this.email = null;
      this.Forgot_User = !1;
      this.Show_User_Password = !1;
      this.Forgot_Password = !0;
    },
    ForgotPasswordSubmit() {
      if(!this.email||this.email===''||this.email===null){return;}
      this.$axios.post(`forgot-password`, {email: this.email,})
          .then(data => {
            if (data.data && data.data.success) {
              this.alertSuccess(data.data.message);
              this.$nextTick(() => {
                this.Forgot_Password = !1;
                this.Forgot_User = !1;
                this.Show_User_Password = !0;
              });
            }
            else if(data.data && !data.data.success){
              this.alertError(data.data.message);
            }
          }).catch(({response}) => {
        const {data} = response || {};
        data && data.message && this.alertError(data.message) && data.errors;
        console.log(data.data);
      });
    },
    ForgotUser() {
      this.form.password = null;
      this.form.login_id = null;
      this.email = null;
      this.Forgot_Password = !1;
      this.Forgot_User = !this.Forgot_User;
      this.Show_User_Password = !1;
    },
    ForgotUserSubmit() {
      if(!this.email||this.email===''||this.email===null){return;}
      this.$axios.post(`forgot-user`, {email: this.email,})
          .then(data => {
            if (data.data && data.data.success) {
              this.alertSuccess(data.data.message);
              this.$nextTick(() => {
                this.email = null;
                this.Forgot_Password = !1;
                this.Forgot_User = !1;
                this.Show_User_Password = !0;
              });
            }
            else if(data.data && !data.data.success){
              this.alertError(data.data.message);
            }
          }).catch(({response}) => {
        const {data} = response || {};
        data && data.message && this.alertError(data.message) && data.errors;
        console.log(data.data);
      });
    },
    login() {
      localStorage.removeItem('current_user');
      localStorage.removeItem('perms');
      localStorage.removeItem('backend_routes');
      localStorage.removeItem(this.localstorageKey);
      this.loading = !0;
      return this.$api.methods.auth.login(this.form)
          .then(({data}) => {
            if (data && data.token) {
              this.loginUser(data.token, data.data || {}, true)
            }
            else
              this.loading = !1
          })
          .catch(({response}) => {
            const {data} = response || {}
            if (data) {
              this.errors = data.errors || []
              this.alertError(data.message)
            }
            this.loading = !1
          })
    },
  }
}
</script>

