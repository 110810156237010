export default {
    menu_url: 'Menu url',
    menu_parent_id: 'Menu parent',
    menu_type: 'Menu Type',
    sort_order: 'Sort order',
    status: 'Status',
    model_name: 'Model Name',
    perm: 'Permission',
    icon: 'Icon',
    system_type: 'System type',
};
