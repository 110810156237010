

<template>
  <app-btn
      :color="color"
      type="submit"
      v-bind="$attrs"
      v-on="$listeners"
  >
    <slot></slot>
  </app-btn>
</template>

<script>
export default {
  name: "Submit",
  props: {
    color: {
      default: () => 'primary'
    },
  }
}
</script>
