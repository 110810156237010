/*
  SarhSoft Cross-PlatForm Engine Project
  Languages: PHP7.4, JavaScript, Node, Java.
  BackEnd: Laravel8.
  FrontEnd: Vue2.
  Project Type: (Laravel,Vue Mixin) Java Cross Frame.
  Written By: M.Farhat,
  Email: m.farhat@sarhsoft.com.
  Copyright © 2022 SarhSoft All rights reserved.
*/
import axios from 'axios';
const prefix = a => 'Panel/LubricantSpecification' + (a ? `/${a}` : '');
export default {
  index(params, config = {}) {
    const url = prefix();
    return params === true ? url : axios.get(url, {...config, params});
  },
  store(data = {}, config = {}) {
    const url = prefix();
    return data === true ? url : axios.post(url, data, config);
  },
  update(id, data = {}, config = {}) {
    const url = prefix(id);
    return id === true ? url : axios.put(url, data, config);
  },
  show(id, config = {}) {
    const url = prefix(id);
    return id === true ? url : axios.get(url, config);
  },
  destroy(id, config = {}) {
    const url = prefix(id);
    return id === true ? url : axios.delete(url, config);
  }
};
