export default {
  manufacturer_id: 'Manufacturer',
  km: 'KM',
  product_sku: 'SKU',
  product_model_id: 'Vehicle Model',
  product_year_id: 'Manufacturing Year',
  product_out_color_id: 'Product Exterior Color',
  product_in_color_id: 'Product Interior Color',
  import_type_id: 'Import Type',
  fuel_type_id: 'Fuel Type',
  chassie: 'Chassie',
  price: 'Price',
  down_payment: 'Down payment',
  last_payment: 'Last payment',
  body_check: 'Body Check',
  notes: 'Notes',
  product_notes: 'Additional Specifications',
  product_type0: 'None',
  product_type1: 'Vehicle',
  product_type2: 'Spare Part',
  stamp_status: 'Stamp status',
  stamp_status0: 'Disabled Stamp',
  stamp_status1: 'Enabled Stamp',
  location: 'Location',
  stamp_location: 'Stamp Location',
  stamp_location0: 'Stamp Location',
  stamp_location1: 'Stamp Location',
  stamp_location2: 'Stamp Location',
  stamp_location3: 'Stamp Location',
  stamp_location4: 'Stamp Location',
  stamp_location5: 'Stamp Location',
  stamp_color: 'Stamp Color',
  stamp_string_en: 'En Stamp string',
  stamp_string_ar: 'Ar Stamp string',
};
